:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-text: #333;
  --adm-color-text-secondary: #666;
  --adm-color-weak: #999;
  --adm-color-light: #ccc;
  --adm-color-border: #eee;
  --adm-color-background: #fff;
  --adm-color-white: #fff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue", helvetica, segoe ui, arial, roboto, "PingFang SC", "miui", "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  --adm-border-color: var(--adm-color-border);
}

html[data-prefers-color-scheme="dark"] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: gray;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-border-color: var(--adm-color-border);
}

:root {
  -webkit-tap-highlight-color: #0000;
}

body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}

a, button {
  cursor: pointer;
}

a {
  color: var(--adm-color-primary);
  transition: opacity .2s ease-in-out;
}

a:active {
  opacity: .8;
}

.adm-plain-anchor {
  color: unset;
  transition: none;
}

.adm-plain-anchor:active {
  opacity: unset;
}

body.adm-overflow-hidden {
  overflow: hidden !important;
}

div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: fixed;
  bottom: -100vh;
  right: -100vw;
}

.adm-auto-center {
  justify-content: center;
  display: flex;
}

.adm-auto-center-content {
  flex: 0 auto;
}

.adm-toast-mask .adm-toast-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.adm-toast-mask .adm-toast-main {
  width: auto;
  max-width: 204px;
  max-height: 70%;
  color: #fff;
  word-break: break-all;
  pointer-events: all;
  font-size: var(--adm-font-size-7);
  box-sizing: border-box;
  background-color: #000000b3;
  border-radius: 8px;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.adm-toast-mask .adm-toast-main-text {
  min-width: 0;
  padding: 12px;
}

.adm-toast-mask .adm-toast-main-icon {
  min-width: 150px;
  padding: 35px 12px;
}

.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 8px;
  font-size: 36px;
  line-height: 1;
}

.adm-toast-loading {
  --size: 48px;
  margin: 0 auto 8px;
}

.adm-mask {
  --z-index: var(--adm-mask-z-index, 1000);
  z-index: var(--z-index);
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.adm-mask-aria-button {
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.adm-mask-content {
  z-index: 1;
}

.adm-popup {
  --z-index: var(--adm-popup-z-index, 1000);
  z-index: var(--z-index);
  position: fixed;
}

.adm-popup-body {
  background-color: var(--adm-color-background);
  z-index: calc(var(--z-index)  + 10);
  position: fixed;
}

.adm-popup-body .adm-popup-close-icon {
  z-index: 100;
  position: absolute;
}

.adm-popup-body-position-bottom {
  width: 100%;
  bottom: 0;
  left: 0;
}

.adm-popup-body-position-bottom .adm-popup-close-icon {
  top: 8px;
  right: 8px;
}

.adm-popup-body-position-top {
  width: 100%;
  top: 0;
  left: 0;
}

.adm-popup-body-position-top .adm-popup-close-icon {
  bottom: 8px;
  right: 8px;
}

.adm-popup-body-position-left {
  height: 100%;
  top: 0;
  left: 0;
}

.adm-popup-body-position-left .adm-popup-close-icon {
  top: 8px;
  right: 8px;
}

.adm-popup-body-position-right {
  height: 100%;
  top: 0;
  right: 0;
}

.adm-popup-body-position-right .adm-popup-close-icon {
  top: 8px;
  left: 8px;
}

.adm-popup-close-icon {
  cursor: pointer;
  color: var(--adm-color-weak);
  padding: 4px;
  font-size: 18px;
  line-height: 1;
}

.adm-picker-view {
  --height: 240px;
  --item-height: 34px;
  --item-font-size: var(--adm-font-size-8);
  height: var(--height);
  width: 100%;
  background: var(--adm-color-background);
  display: flex;
  position: relative;
  overflow: hidden;
}

.adm-picker-view-column {
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  z-index: 0;
  flex: 1;
  position: relative;
}

.adm-picker-view-column-wheel {
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  top: calc(50% - var(--item-height) / 2);
  position: absolute;
  left: 0;
}

.adm-picker-view-column-wheel:before {
  content: " ";
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  top: -100vh;
}

.adm-picker-view-column-wheel:after {
  content: " ";
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  bottom: -100vh;
}

.adm-picker-view-column-item {
  font-size: var(--item-font-size);
  height: var(--item-height);
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.adm-picker-view-column-item-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.adm-picker-view-column-accessible {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  top: calc(var(--item-height) * -1);
  z-index: 0;
  flex-direction: column;
  padding-bottom: 1px;
  display: flex;
  position: relative;
}

.adm-picker-view-column-accessible > * {
  text-overflow: ellipsis;
  flex: 1;
}

.adm-picker-view-column-accessible-current {
  width: 100%;
  height: 100%;
  position: absolute;
}

.adm-picker-view-column-accessible-button {
  width: 100%;
  height: 100%;
}

.adm-picker-view-mask {
  z-index: 10000;
  width: 100%;
  height: 100%;
  pointer-events: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.adm-picker-view-mask-top, .adm-picker-view-mask-bottom {
  flex: auto;
}

.adm-picker-view-mask-middle {
  height: var(--item-height);
  box-sizing: border-box;
  border-top: solid 1px var(--adm-color-border);
  border-bottom: solid 1px var(--adm-color-border);
  flex: none;
}

.adm-picker-view-mask-top {
  background: var(--adm-color-background);
  -webkit-mask: linear-gradient(0deg, #0009 0%, #000c 50%, #000 100%);
  mask: linear-gradient(0deg, #0009 0%, #000c 50%, #000 100%);
}

.adm-picker-view-mask-bottom {
  background: var(--adm-color-background);
  -webkit-mask: linear-gradient(#0009 0%, #000c 50%, #000 100%);
  mask: linear-gradient(#0009 0%, #000c 50%, #000 100%);
}

.adm-picker-view-loading-content {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adm-picker-view-item-height-measure {
  height: var(--item-height);
  width: 0;
  pointer-events: none;
  position: relative;
  top: 0;
  left: 0;
}

.adm-spin-loading {
  --color: var(--adm-color-weak);
  --size: 32px;
  width: var(--size);
  height: var(--size);
}

.adm-spin-loading-svg {
  width: 100%;
  height: 100%;
  animation: .8s linear infinite adm-spin-loading-rotate;
}

.adm-spin-loading-svg > .adm-spin-loading-fill {
  stroke: var(--color);
}

@keyframes adm-spin-loading-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.adm-ellipsis {
  line-height: 1.5;
  overflow: hidden;
}

.adm-scroll-mask {
  z-index: 1;
  width: 30px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
}

.adm-scroll-mask-left {
  background: linear-gradient(to right, var(--adm-color-background), #fff0);
  left: 0;
}

.adm-scroll-mask-right {
  background: linear-gradient(to left, var(--adm-color-background), #fff0);
  right: 0;
}

.adm-capsule-tabs {
  min-width: 0;
  position: relative;
}

.adm-capsule-tabs-header {
  border-bottom: solid 1px var(--adm-color-border);
  padding: 12px 6px;
  position: relative;
}

.adm-capsule-tabs-tab-list {
  scrollbar-width: none;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.adm-capsule-tabs-tab-list::-webkit-scrollbar {
  display: none;
}

.adm-capsule-tabs-tab-wrapper {
  flex: auto;
  padding: 0 6px;
}

.adm-capsule-tabs-tab {
  cursor: pointer;
  font-size: var(--adm-font-size-7);
  text-align: center;
  white-space: nowrap;
  background-color: var(--adm-color-fill-content);
  border-radius: 20px;
  margin: 0 auto;
  padding: 8px 20px;
  position: relative;
}

.adm-capsule-tabs-tab-active {
  color: var(--adm-color-text-light-solid);
  background-color: var(--adm-color-primary);
}

.adm-capsule-tabs-tab-disabled {
  opacity: .5;
  cursor: not-allowed;
}

.adm-capsule-tabs-content {
  padding: 12px;
}

.adm-calendar .adm-calendar-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  display: flex;
}

.adm-calendar .adm-calendar-header a.adm-calendar-arrow-button {
  flex: none;
  padding: 4px 8px;
  display: block;
}

.adm-calendar .adm-calendar-header a.adm-calendar-arrow-button svg {
  height: 22px;
}

.adm-calendar .adm-calendar-header a.adm-calendar-arrow-button.adm-calendar-arrow-button-right svg {
  transform: rotate(180deg);
}

.adm-calendar .adm-calendar-header .adm-calendar-title {
  font-size: var(--adm-font-size-10);
  text-align: center;
  flex: auto;
}

.adm-calendar .adm-calendar-body {
  flex-wrap: wrap;
  display: flex;
}

.adm-calendar-cells {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px 8px 4px;
  display: flex;
}

.adm-calendar-cell {
  box-sizing: border-box;
  width: 14.2857%;
  height: 48px;
  color: var(--adm-color-text);
  cursor: pointer;
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
  padding: 2px;
  display: flex;
}

.adm-calendar-cell.adm-calendar-cell-today {
  color: var(--adm-color-primary);
}

.adm-calendar-cell.adm-calendar-cell-disabled, .adm-calendar-cell.adm-calendar-cell-disabled .adm-calendar-cell-bottom {
  color: var(--adm-color-light);
}

.adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected {
  background: var(--adm-color-primary);
  color: var(--adm-color-white);
}

.adm-calendar-cell.adm-calendar-cell-selected .adm-calendar-cell-bottom {
  color: var(--adm-color-white);
}

.adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected-begin {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected-end {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.adm-calendar-cell.adm-calendar-cell-disabled.adm-calendar-cell.adm-calendar-cell-selected {
  color: var(--adm-color-light);
}

.adm-calendar-cell .adm-calendar-cell-top {
  font-size: var(--adm-font-size-10);
  flex: none;
}

.adm-calendar-cell .adm-calendar-cell-bottom {
  font-size: var(--adm-font-size-4);
  height: 12px;
  color: var(--adm-color-weak);
  flex: none;
  line-height: 12px;
}

.adm-calendar-mark {
  border-bottom: solid 1px var(--adm-color-border);
  height: 45px;
  box-sizing: border-box;
  font-size: var(--adm-font-size-7);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.adm-calendar-mark .adm-calendar-mark-cell {
  text-align: center;
  flex: 1;
}

.adm-dot-loading {
  display: inline-block;
}

.adm-button {
  --color: var(--adm-color-text-light-solid);
  --text-color: var(--adm-button-text-color, var(--adm-color-text));
  --background-color: var(--adm-button-background-color, var(--adm-color-background));
  --border-radius: var(--adm-button-border-radius, 4px);
  --border-width: var(--adm-button-border-width, 1px);
  --border-style: var(--adm-button-border-style, solid);
  --border-color: var(--adm-button-border-color, var(--adm-color-border));
  color: var(--text-color);
  background-color: var(--background-color);
  box-sizing: border-box;
  height: auto;
  font-size: var(--adm-font-size-9);
  text-align: center;
  border: var(--border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 7px 12px;
  line-height: 1.4;
  transition: opacity .15s;
  display: inline-block;
  position: relative;
}

.adm-button:focus {
  outline: none;
}

.adm-button:before {
  transform: translate(calc(var(--border-width) * -1), calc(var(--border-width) * -1));
  width: 100%;
  height: 100%;
  border: var(--border-width) var(--border-style) #000;
  border-radius: var(--border-radius);
  opacity: 0;
  content: " ";
  box-sizing: content-box;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.adm-button:active:before {
  opacity: .08;
}

.adm-button-default.adm-button-fill-outline {
  --background-color: transparent;
  --border-color: var(--adm-color-text);
}

.adm-button-default.adm-button-fill-none {
  --background-color: transparent;
  --border-width: 0px;
}

.adm-button:not(.adm-button-default) {
  --text-color: var(--adm-color-text-light-solid);
  --background-color: var(--color);
  --border-color: var(--color);
}

.adm-button:not(.adm-button-default).adm-button-fill-outline {
  --text-color: var(--color);
  --background-color: transparent;
}

.adm-button:not(.adm-button-default).adm-button-fill-none {
  --text-color: var(--color);
  --background-color: transparent;
  --border-width: 0px;
}

.adm-button-primary {
  --color: var(--adm-color-primary);
}

.adm-button-success {
  --color: var(--adm-color-success);
}

.adm-button-danger {
  --color: var(--adm-color-danger);
}

.adm-button-warning {
  --color: var(--adm-color-warning);
}

.adm-button-block {
  width: 100%;
  display: block;
}

.adm-button-disabled {
  cursor: not-allowed;
  opacity: .4;
}

.adm-button-disabled:active:before {
  display: none;
}

.adm-button.adm-button-mini {
  font-size: var(--adm-font-size-main);
  padding-top: 3px;
  padding-bottom: 3px;
}

.adm-button.adm-button-mini.adm-button-shape-rounded {
  padding-left: 9px;
  padding-right: 9px;
}

.adm-button.adm-button-small {
  font-size: var(--adm-font-size-7);
  padding-top: 3px;
  padding-bottom: 3px;
}

.adm-button.adm-button-large {
  font-size: var(--adm-font-size-10);
  padding-top: 11px;
  padding-bottom: 11px;
}

.adm-button.adm-button-shape-rounded {
  --border-radius: 1000px;
}

.adm-button.adm-button-shape-rectangular {
  --border-radius: 0;
}

.adm-button-loading {
  vertical-align: bottom;
}

.adm-button-loading-wrapper {
  height: 1.4em;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adm-button-loading-wrapper > .adm-loading {
  opacity: .6;
}

/*# sourceMappingURL=index.css.map */
